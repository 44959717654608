.emoji-popup-content {
  background: none;
  border: none;
}
::-webkit-scrollbar {
  display: none;
}
.MuiFilledInput-underline:after {
  border-bottom: 2px solid #633d8c !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #633d8c !important;
}
#input-container > div:nth-child(3) {
  background-color: transparent !important;
  border-radius: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
  display: -webkit-inline-box !important;
  overflow-x: scroll;
}
.popup-content {
  width: 50% !important;
}
@media screen and (max-width: 800px) {
  .popup-content {
    width: 90vw !important;
 }
}