.group {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.group .form-input {
  background: none;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  padding: 10px 10px 10px 7px;
  display: block;
  width: 80%;
  border: none;
  border-radius: 3px;
  margin: 15px 0;
}
.group .form-input:focus {
  outline: none;
}
.group .form-input:focus ~ .form-input-label {
  top: -10px;
  left: 12%;
  font-size: 12px;
  color: white;
}
.group input[type="password"] {
  letter-spacing: 0.3em;
}
.group .form-input-label {
  color: #737373;
  opacity: 1;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12%;
  top: 25px;
  transition: 300ms ease all;
}
.group .form-input-label.shrink {
  top: -10px;
  left: 12%;
  font-size: 12px;
  color: white;
}
.group .see-more-label {
  left: 80px !important;
}